<template>
  <v-dialog :value="value" persistent width="850">
    <v-card>
      <v-container class="ma-0 pa-0" fluid>
        <v-data-table
          :headers="headers"
          :height="tableSize"
          :loading="loading"
          :items="items"
          :items-per-page="-1"
          dense
          fixed-header
          hide-default-footer
          no-data-text="No Items to Display">
          <template #top>
            <v-toolbar dark flat color="main">
              <v-toolbar-title class="font-weight-light">
                {{ shipper.item_desc }}
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template #[`item.item_id`]="{ item }">
            <span v-if="item.item_id">
              {{ item.item_id }}
            </span>
            <span v-else>-</span>
          </template>

          <template #[`item.qty_per_case`]="{ item }">
            <span v-if="item.qty_per_case">
              {{ item.qty_per_case }}
            </span>
            <span v-else>-</span>
          </template>

          <template #[`item.last_90_days_shipped_qty`]="{ item }">
            <span v-if="item.last_90_days_shipped_qty != null">
              {{ item.last_90_days_shipped_qty }}
            </span>
            <span v-else>-</span>
          </template>
        </v-data-table>
        <v-divider />
        <v-toolbar flat>
          <v-spacer />
          <v-btn
            class="text-capitalize"
            text
            @click="$emit('update:value', false)">
            Close
          </v-btn>
        </v-toolbar>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import OrderGrid from '@/axios/order-grid'
import { fullwidth } from '@/mixins/fullwidth'
import { displayHelpers } from '@/mixins/displayHelpers'
import { utils } from '@/mixins/utils'
export default {
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { class: 'accent white--text', sortable: false },
        {
          text: 'Order #',
          sortable: true,
          class: 'accent white--text',
          filterable: true,
          value: 'item_id',
          width: '100'
        },
        {
          text: 'Description',
          sortable: true,
          class: 'accent white--text',
          filterable: true,
          value: 'description',
          width: 'auto'
        },
        {
          text: 'UPC',
          sortable: true,
          class: 'accent white--text',
          filterable: true,
          value: 'upc',
          width: '145'
        },
        {
          text: 'Qty',
          sortable: true,
          class: 'accent white--text',
          filterable: true,
          value: 'quantity',
          width: '75'
        },
        {
          text: 'Pack Size',
          sortable: true,
          class: 'accent white--text',
          filterable: true,
          value: 'size',
          width: '125'
        }
      ]
    }
  },
  name: 'ComponentItems',
  mixins: [fullwidth, displayHelpers, utils],
  props: {
    value: Boolean,
    shipper: Object
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.initComponentItems()
      }
    }
  },
  methods: {
    async initComponentItems() {
      const { item_id } = this.shipper
      this.loading = true
      try {
        const response = await OrderGrid.getShipperComponents(item_id)
        this.items = response.data
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.noWrap {
  white-space: nowrap;
}
</style>
